<template>
  <div class="seckillInfoList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>预售列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>预售名称:</span>
      <el-input v-model="list.presaleName" placeholder="请输入活动名称"></el-input>
      <span>状态:</span>
      <el-select v-model="list.status" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span>是否显示:</span>

      <el-select clearable v-model="list.isShow" placeholder="请选择">
        <el-option
          v-for="item in isShowOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getseckillInfoList()">查找</el-button>
      <el-button type="primary" @click="$router.push(`/addPresale/0/0/0`)"
        >添加</el-button
      >
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <!-- <el-table-column
          align="center"
          type="selection"
          width="width"
        ></el-table-column> -->
      <el-table-column align="center" prop="detp" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="presaleId"
        label="活动ID"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="presaleName"
        label="活动名称"
        width="300"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="presaleTime"
        label="活动时间"
        width="300"
      ></el-table-column>
      <el-table-column align="center" label="活动图片">
        <template slot-scope="{ row }">
          <el-image
            id="img"
            :src="row.imgUrl"
            :preview-src-list="[row.imgUrl]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="strIsBack"
        label="是否退订"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strChange"
        label="是否改期"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="label"
        label="标签"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="stock"
        label="活动数量"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strStatus"
        label="活动状态"
        width="width"
      ></el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示"> </el-table-column>
      <el-table-column label="操作" width="350">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            style="margin: 5px"
            @click="onInvalidRow(row.presaleId)"
            >使失效</el-button
          >

          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/addPresale/${row.presaleId}/0/0`)"
            >编辑</el-button
          >

          <el-button size="mini" type="primary" @click="onDelRow(row.presaleId)"
            >删除</el-button
          >

          <el-button size="mini" type="primary" @click="onIsUpgrade(row.presaleId)"
            >升级设置</el-button
          >
          <el-button size="mini" type="primary" @click="onShowAddress(row.presaleId)"
            >查看页面地址</el-button
          >
          <el-button size="mini" type="primary" @click="onSetPrice(row.presaleId)"
            >设置价格</el-button
          >
          <el-button size="mini" type="primary" @click="createCode(row.presaleId)"
            >生成小程序码</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog title="查看页面详情地址" :visible.sync="showSrc" width="600px">
      <div id="address">
        前台详情地址:<el-input
          style="width: 350px"
          v-model="address"
          readonly
          id="wrapper"
        ></el-input>
        <el-button type="primary" icon="el-icon-document-copy" @click="urla"
          >一键复制</el-button
        >
      </div>
      <div slot="footer">
        <el-button @click="showSrc = false">取 消</el-button>
        <el-button type="primary" @click="showSrc = false">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPresaleList,
  getPresaleListUpdata,
  getPresaleListDel,
  getPresaleListUp,
  getPresaleListSort,
} from "../../../api/seckillInfo";
import { createEr8 } from "../../../api/erCode";
export default {
  name: "seckillInfoList",
  data() {
    return {
      presaleId: "",
      QRcodeSize: "",
      codeVisible: false,
      imgUrl: "",
      isShowOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      options: [
        { value: 0, label: "全部" },
        { value: 1, label: "进行中" },
        { value: 2, label: "未开始" },
        { value: 3, label: "已结束" },
        { value: 4, label: "已失效" },
      ],
      address: "",
      showSrc: false,
      tableData: [],
      pagination: {},
      list: {
        currentPage: 1,
        pageSize: 5,
        presaleName: "",
        status: null,
        isShow: -2,
      },
    };
  },
  created() {
    this.getseckillInfoList();
  },
  methods: {
    createCode(id) {
      this.codeVisible = true;
      this.presaleId = id;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr8({
          presaleId: this.presaleId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    onSetPrice(id) {
      this.$router.push(`/presalePrice/${id}`);
    },
    onShowAddress(id) {
      this.address = "seckill/pages/presellDetail/presellDetail?presaleId=" + id;
      this.showSrc = true;
    },
    urla() {
      var inputElement = document.getElementById("wrapper");
      //选中input框的内容
      inputElement.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
    },
    async getseckillInfoList() {
      const { data } = await getPresaleList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await getPresaleListSort({
        presaleId: row.presaleId,
        dept: row.dept,
      });
      row.inputVisible = false;
      this.getseckillInfoList();
    },
    async onIsUpgrade(presaleId) {
      const { data } = await getPresaleListUp({
        presaleId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/presaleUpgradeList/${presaleId}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    onDelRow(presaleId) {
      this.$confirm("是否要删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await getPresaleListDel({
            presaleId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getseckillInfoList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onInvalidRow(presaleId) {
      this.$confirm("是否要让这条预售失效?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await getPresaleListUpdata({
            presaleId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: data.msg,
            });
            this.getseckillInfoList();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getseckillInfoList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getseckillInfoList();
    },
  },
};
</script>
<style lang="less" scoped>
.seckillInfoList {
  //解决未知的窗口抖动👇
  padding-right: 0px !important;
  overflow: hidden;
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-input {
      width: 400px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .el-table {
    margin-top: 50px;
    #img {
      width: 108px;
      height: 61px;
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>
